import React from 'react';
import ComingSoon from 'Pages/ComingSoon';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import LandingPage from 'Pages/LandingPage';
// import Onboard from 'Pages/Onboarding';
// import Dashboard from 'Pages/Dashboard';
// import Login from 'Pages/Auth/Login';
// import SignUp from 'Pages/Auth/SignUp';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/coming-soon">
          <ComingSoon />
        </Route>
        {/* <Route path="/onboard">
          <Onboard />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/create-account">
          <SignUp />
        </Route>
        <Route path="/login">
          <Login />
        </Route> */}

        <Route path="/" exact>
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
